import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import myEmployeesQuery from './myEmployees.graphql';
import selfQuery from './self.graphql';
import Table from 'cccisd-table';

const Body = ({ year, month, self = false }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        getMonthlyStatus();
    }, []);

    const getMonthlyStatus = async () => {
        const query = self ? selfQuery : myEmployeesQuery;

        const response = await client.query({
            query,
            fetchPolicy: 'network-only',
            variables: { year, month, weeksWithinMonth: true },
        });

        const { selfEmployee } = response.data.timeTracker;
        const weeksOfMonth = selfEmployee.week.weeksOfMonth;
        const employeeList = self ? [selfEmployee] : selfEmployee.myEmployeeList;

        setData(
            employeeList.map(employee => {
                return {
                    fullName: employee.user.fullName,
                    ...employee.weekList.reduce((current, weekRow) => {
                        current[`week_${weekRow.week}`] = { approved: weekRow.approved, submitted: weekRow.submitted };
                        return current;
                    }, {}),
                };
            })
        );

        setColumns([
            {
                name: 'fullName',
                label: 'Employee',
            },
            ...weeksOfMonth.map(week => {
                return {
                    name: `week_${week}`,
                    label: `Week ${week}`,
                    class: 'text-center',
                    setClass: value => {
                        if (value?.approved) return 'text-center text-white bg-success';
                        if (value?.submitted) return 'text-center text-white bg-warning';
                        return 'text-center text-white bg-danger';
                    },
                    render: value => {
                        if (value?.approved) return 'Approved';
                        if (value?.submitted) return 'Submitted';
                        return 'Missing';
                    },
                };
            }),
        ]);

        setIsLoading(false);
    };

    return (
        <Loader type="inline" loading={isLoading} removeChildren>
            <Table
                name="monthly-status"
                saveState={false}
                rowKey="fullName"
                perPage={1000}
                showPerPageOptions={false}
                columns={columns}
                data={data}
                hideShowingRowsSummary
            />
        </Loader>
    );
};

export default Body;
