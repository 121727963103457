import React, { useState } from 'react';
import Style from './style.css';
import _round from 'lodash/round';
import TableTop from 'cccisd-tabletop';
import DatePicker from 'cccisd-datepicker';
import Tooltip from 'cccisd-tooltip';
import Modal from 'cccisd-modal';
import IconInfo from 'cccisd-icons/question4';
import messagesQuery from './messages.graphql';
import clientAppsQuery from './clientApps.graphql';

const SmsMonitoring = () => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const messagesTable = {
        orderBy: 'canonicalAt',
        isAscentOrder: false,
        query: messagesQuery,
        rowKey: 'transactionId',
        noRecordsMessage: 'No SMS Messages found.',
        columns: [
            {
                name: 'app.label',
                label: 'Application',
                filter: true,
                sort: true,
            },
            {
                name: 'app.url',
                label: 'URL',
                filter: true,
                sort: true,
            },
            {
                name: 'questproject',
                label: 'Quest Project',
                filter: true,
                sort: true,
            },
            {
                name: 'status',
                label: 'Status',
                filter: true,
                sort: true,
                filterSettings: {
                    type: 'selectbox',
                    options: [
                        { label: 'Success', value: 'SUCCESS' },
                        { label: 'Failure', value: 'FAILURE' },
                        { label: 'Sent', value: 'sent' },
                    ],
                },
                render: ({ value }) => {
                    if (value === 'SUCCESS') {
                        return <span className="badge bg-success">Success</span>;
                    }
                    if (value === 'FAILURE') {
                        return <span className="badge bg-danger">Failure</span>;
                    }
                    if (value === 'sent') {
                        return <span className="badge bg-info">Sent</span>;
                    }
                    return <span className="badge bg-secondary">{value}</span>;
                },
            },
            {
                name: 'message',
                label: 'Message',
                filter: true,
                sort: true,
            },
            {
                name: 'phoneNumber',
                label: 'Phone',
                filter: true,
                sort: true,
            },
            {
                name: 'canonicalAt',
                label: 'Sent At',
                filter: true,
                sort: true,
                filterSettings: {
                    type: 'date',
                },
            },
            {
                name: 'transactionId',
                label: '',
                render: ({ row }) => {
                    let nameBlock =
                        row.firstName || row.lastName ? (
                            <div>
                                Name: {row.firstName} {row.lastName}
                            </div>
                        ) : (
                            ''
                        );
                    let messageBlock = row.body ? (
                        <div>
                            <div>Message:</div>
                            <pre>{row.body}</pre>
                        </div>
                    ) : (
                        ''
                    );

                    return (
                        <Modal
                            title="Additional Info"
                            trigger={
                                <Tooltip title="Additional Info">
                                    <button type="button" className="btn btn-xs btn-primary">
                                        <IconInfo />
                                    </button>
                                </Tooltip>
                            }
                        >
                            <div>{`Phone #: ${row.phoneNumber}`}</div>
                            {nameBlock}
                            {messageBlock}
                            <div>{`Carrier: ${row.carrier}`}</div>
                            <div>{`Price: ${row.price}`}</div>
                            <br />
                            <div className={Style.ids}>
                                <em>{`Transaction ID: ${row.transactionId}`}</em>
                            </div>
                            <div className={Style.ids}>
                                <em>{`Delivery Status: ${row.status}`}</em>
                            </div>
                            <div className={Style.ids}>
                                <em>{`Status Message: ${row.message}`}</em>
                            </div>
                            <div className={Style.ids}>
                                <em>{`SMS Log ID: ${row.smsLogId}`}</em>
                            </div>
                        </Modal>
                    );
                },
            },
        ],
    };

    const clientAppsTable = {
        query: clientAppsQuery,
        rowKey: 'appId',
        orderBy: 'url',
        noRecordsMessage: 'No Client Apps found.',
        showTotal: true,
        graphqlVariables: {
            smsFilter: {
                range: {
                    field: 'cloudwatchAtDate',
                    fromString: from || '0000-01-01',
                    toString: to || '9999-12-31',
                },
            },
        },
        columns: [
            {
                name: 'label',
                label: 'Client App',
                filter: true,
                sort: true,
                render: ({ row, value }) => {
                    if (row.description) {
                        return (
                            <span>
                                {value}
                                <Tooltip title={row.description}>
                                    <IconInfo spaceLeft />
                                </Tooltip>
                            </span>
                        );
                    }
                    return value;
                },
            },
            {
                name: 'url',
                label: 'URL',
                filter: true,
                sort: true,
            },
            {
                name: 'environment',
                label: 'Environment',
                filter: true,
                sort: true,
            },
            {
                name: 'smsCount',
                label: 'Messages Sent',
                filter: true,
                sort: true,
                totalPath: 'appSummary.smsCountTotal',
            },
            {
                name: 'smsSummary.totalCost',
                label: 'Cost',
                filter: true,
                sort: true,
                totalPath: 'appSummary.smsCostTotal',
                render: ({ value }) => {
                    return `$${_round(value, 2)}`;
                },
            },
        ],
    };

    const DateRangePicker = () => {
        return (
            <div style={{ display: 'flex', gap: '1em', alignItems: 'baseline' }}>
                <label className="form-label">From:</label>
                <DatePicker selected={from} onChange={v => setFrom(v)} placeholderText="Choose a date" />
                <label className="form-label">To:</label>
                <DatePicker selected={to} onChange={v => setTo(v)} placeholderText="Choose a date" />
                {(from || to) && (
                    <button
                        className="btn btn-sm"
                        type="button"
                        onClick={() => {
                            setFrom('');
                            setTo('');
                        }}
                    >
                        Clear Date Filter
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className={'row ' + Style.wrapper}>
            <h1>SMS Monitoring</h1>
            <TableTop
                handle="smsMonitoring"
                saveState
                views={[
                    {
                        handle: 'messagesTable',
                        title: 'SMS Messages',
                        table: messagesTable,
                    },
                    {
                        handle: 'clientAppTable',
                        title: 'Client Apps',
                        table: clientAppsTable,
                        buttons: [DateRangePicker],
                    },
                ]}
            />
        </div>
    );
};

export default SmsMonitoring;
