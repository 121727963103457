import React from 'react';

export default () => (
    <button
        type="button"
        className="btn btn-sm btn-secondary"
        onClick={() => window.open('https://monitoring.3cisd.com/clientSupportSurvey', '_blank')}
    >
        Customer support
    </button>
);
