import React, { useEffect, useState } from 'react';
import Tabs from 'cccisd-tabs';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';

import clientDropdown from './clients.graphql';
import ClientTable from './ClientTable/index.js';
import PerClientView from './PerClientView/index.js';
import ClientSupportPlayer from './ClientSupportPlayer/index.js';
import style from './style.css';
import { renderAlert } from '../dashboardHelpers.js';

import IconHourglass from 'cccisd-icons/hour-glass';

const ClientSupportDashboard = () => {
    const [saasOrgs, setSaasOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);

    useEffect(() => {
        getOrgList();
    }, []);

    async function getOrgList() {
        const data = await client.query({ query: clientDropdown, fetchPolicy: 'network-only' });

        if (data.data.groups.organizationList.length > 0) {
            setSaasOrgs(data.data.groups.organizationList);
            const defaultProject = data.data.groups.organizationList[0];
            setSelectedOrg(defaultProject);
        }
    }

    const tabList = [
        {
            name: 'clientDropdown',
            title: 'Per Client View',
            content:
                saasOrgs.length > 0 ? (
                    <div>
                        <div className={style.clientViewHeader}>
                            <div className="mb-3" style={{ width: '50%' }}>
                                <label htmlFor="site">SaaS Clients</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {selectedOrg ? (
                                        <select
                                            value={selectedOrg.group.groupId}
                                            className="form-select"
                                            id="site"
                                            onChange={e => {
                                                let desiredProject = saasOrgs.find(org => {
                                                    return Number(org.group.groupId) === Number(e.target.value);
                                                });
                                                setSelectedOrg(desiredProject);
                                            }}
                                        >
                                            {saasOrgs.map(org => (
                                                <option value={org.group.groupId} key={org.group.groupId}>
                                                    {`${org.group.label} - ${org.fields.billingCycle} `}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <Loader />
                                    )}
                                </div>
                            </div>
                            <div className={style.addSupportModal}>
                                <Modal
                                    trigger={
                                        <button className="btn btn-primary" type="button">
                                            <IconHourglass spaceRight />
                                            Report Customer Support
                                        </button>
                                    }
                                    title="Report Customer Support"
                                    headerStyle={{
                                        backgroundColor: '#007298',
                                        color: '#fff',
                                    }}
                                    formAutoFocus
                                    render={({ closeModal }) => (
                                        <div className={style.fixContainer}>
                                            <ClientSupportPlayer closeModal={closeModal} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <hr />
                        <PerClientView selectedOrg={selectedOrg} />
                    </div>
                ) : (
                    renderAlert('Please add at least 1 SaaS Client to Manage', 'brokenHeart')
                ),
        },
        {
            name: 'calendarYearTable',
            title: 'Calendar Year Clients',
            content: (
                <ClientTable
                    tableFilter={{ filter: { eq: { field: 'fields.billingCycle', string: 'calendar' } } }}
                    tableName="Calendar Year Clients"
                />
            ),
        },
        {
            name: 'midYearTable',
            title: 'Mid-Year Clients',
            content: (
                <ClientTable
                    tableFilter={{ filter: { eq: { field: 'fields.billingCycle', string: 'midYear' } } }}
                    tableName="Mid-Year Clients"
                />
            ),
        },
    ];

    return <Tabs tabList={tabList} />;
};

export default ClientSupportDashboard;
