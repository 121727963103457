import React, { useEffect, useState } from 'react';
import TableTop from 'cccisd-tabletop';
import jiraTickets from './tickets.graphql';
import axios from 'cccisd-axios';
import Paginator from 'cccisd-paginator';
import Loader from 'cccisd-loader';
import IconSearch from 'cccisd-icons/search';

const Tickets = () => {
    const [tickets, setTickets] = useState([]);
    const [search, setSearch] = useState('');
    const [load, setLoad] = useState(true);
    const getTickets = async () => {
        const response = await axios.get('/api/monitoring/searchJira?query=' + search);
        setTickets(response.data.data);
        setLoad(false);
    };
    useEffect(() => {
        getTickets();
    }, []);
    const [pageNumber, setPageNumber] = useState(1);
    return (
        <div>
            <form
                action="/hms/accommodations"
                method="GET"
                onSubmit={event => {
                    event.preventDefault();
                    getTickets();
                }}
            >
                <div className="row">
                    <div className="col-6 col-md-4">
                        <div className="input-group" style={{ marginBottom: '20px' }}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                id="txtSearch"
                                onChange={event => {
                                    setSearch(event.target.value);
                                }}
                            />
                            <div className="input-group-btn">
                                <button className="btn btn-primary" type="button" onClick={getTickets}>
                                    <IconSearch />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <Paginator currentPage={pageNumber} total={Math.ceil(tickets.length / 20)} onPageChange={setPageNumber} />
            {load ? (
                <Loader loading type="inline" />
            ) : (
                <table className="table table-bordered" style={{ marginBottom: '20px' }}>
                    <thead>
                        <tr>
                            <th scope="col">Ticket</th>
                            <th scope="col">Title</th>
                            <th scope="col">Epic</th>
                            <th scope="col">JIRA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets.slice(pageNumber * 20 - 20, pageNumber * 20 - 1).map((ticket, index) => (
                            <tr key={index}>
                                <td className="text-nowrap">
                                    <strong>{ticket.id}</strong>
                                </td>
                                <td>{ticket.title}</td>
                                <td>{ticket.epic}</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                            window.open(ticket.url, '_blank');
                                        }}
                                    >
                                        GO
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <TableTop
                handle="jiraTickets"
                views={[
                    {
                        handle: 'jira_tickets',
                        title: 'Tickets',
                        table: {
                            query: jiraTickets,
                            rowKey: 'group.groupId',
                            columns: [
                                {
                                    name: 'group.label',
                                    label: 'App',
                                    tooltip: <div>This is the internal name for the app&apos;s production site.</div>,
                                    sort: true,
                                    filter: true,
                                },
                                {
                                    name: 'fields.platform_version_production',
                                    label: 'Version',
                                    tooltip: (
                                        <div>
                                            This is the current platform version for the app&apos;s production site.
                                        </div>
                                    ),
                                    sort: true,
                                    filter: true,
                                },
                                {
                                    name: 'fields.commercialized',
                                    label: 'Compliance',
                                    tooltip: (
                                        <div>This is the compliance status for the app&apos;s production site.</div>
                                    ),
                                    sort: true,
                                    filter: true,
                                },
                            ],
                        },
                    },
                ]}
            />
        </div>
    );
};

export default Tickets;
