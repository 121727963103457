import React, { useEffect, useState } from 'react';
import AdjustModal from './AdjustModal';
import axios from 'cccisd-axios';
import { client } from 'cccisd-apollo';
import ClickButton from 'cccisd-click-button';
import confirm from 'cccisd-confirm';
import IconPencil from 'cccisd-icons/pencil';
import Loader from 'cccisd-loader';
import MonthlyStatusModal from './MonthlyStatusModal';
import monthQuery from './months.graphql';
import style from './style.css';
import Table from 'cccisd-table';
import utils from '../utils';

const Boilerplate = window.cccisd.boilerplate;

export default () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [calculating, setCalculating] = useState(0);

    useEffect(() => {
        getMonths();
    }, [year]);

    const getMonths = async () => {
        setIsLoading(true);

        const response = await client.query({
            query: monthQuery,
            fetchPolicy: 'network-only',
            variables: { year },
        });

        const { monthList, submittedSummary, approvedSummary } = response.data.timeTracker;
        const submittedByMonth = (submittedSummary.submitted || [{}])[0]?.byMonth;
        const approvedByMonth = (approvedSummary.approved || [{}])[0]?.byMonth;

        setData(
            [...Array(12).keys()].map(index => {
                const month = index + 1;
                const monthItem = monthList.find(ml => ml.month === month);

                return {
                    month,
                    label: utils.monthToName(month),
                    calculatedAt: monthItem?.calculatedAt,
                    adjustedAt: monthItem?.adjustedAt,
                    submitted: submittedByMonth?.find(ss => parseInt(ss.value, 10) === month)?.valueCount || 0,
                    approved: approvedByMonth?.find(as => parseInt(as.value, 10) === month)?.valueCount || 0,
                };
            })
        );

        setYear(year);
        setIsLoading(false);
    };

    const calculate = async month => {
        setCalculating(month);
        await axios.post(Boilerplate.route('api.monitoring.time-tracker.admin.calculate-month'), { year, month });
        await getMonths();
        setCalculating(0);
    };

    const columns = [
        {
            name: 'label',
            label: 'Month',
        },
        {
            name: 'submitted',
            label: 'Submitted',
            render: (value, row) => {
                return (
                    <MonthlyStatusModal
                        year={year}
                        month={row.month}
                        orderBy="submitted"
                        trigger={
                            <button type="button" className="btn btn-xs btn-secondary">
                                {value}
                            </button>
                        }
                    />
                );
            },
        },
        {
            name: 'approved',
            label: 'Approved',
            render: (value, row) => {
                return (
                    <MonthlyStatusModal
                        year={year}
                        month={row.month}
                        orderBy="approved"
                        trigger={
                            <button type="button" className="btn btn-xs btn-secondary">
                                {value}
                            </button>
                        }
                    />
                );
            },
        },
        {
            name: 'calculatedAt',
            label: 'From Raw',
            render: (value, row) => {
                if (value) {
                    return value;
                }

                if (!row.approved) {
                    return null;
                }

                return (
                    <ClickButton
                        className="btn btn-xs btn-primary"
                        title="Calculate"
                        isLoading={calculating === row.month}
                        isDisabled={isLoading || (calculating > 0 && calculating !== row.month)}
                        onClick={() => {
                            confirm({
                                message: (
                                    <>
                                        End of {utils.monthToName(row.month)} {year}?
                                    </>
                                ),
                                description: (
                                    <>
                                        <p>
                                            Submitted: <strong>{row.submitted}</strong>
                                            <br />
                                            Approved: <strong>{row.approved}</strong>
                                        </p>
                                        <p>
                                            WARNING! This action will transfer raw data to the admin area where it can
                                            be adjusted. This action can only be done once per month and cannot be
                                            undone. Be sure all raw data is submitted for the month before doing this.
                                        </p>
                                    </>
                                ),
                                confirmLabel: 'Proceed',
                                abortLabel: 'Cancel',
                            }).then(() => calculate(row.month));
                        }}
                    />
                );
            },
        },
        {
            name: 'adjust',
            label: 'Adjust',
            render: (value, row) => {
                if (!row.calculatedAt) {
                    return null;
                }

                return (
                    <AdjustModal
                        year={year}
                        month={row.month}
                        callback={getMonths}
                        trigger={
                            <button
                                type="button"
                                className="btn btn-xs btn-primary"
                                disabled={isLoading || calculating > 0}
                            >
                                <IconPencil />
                            </button>
                        }
                    />
                );
            },
        },
        {
            name: 'adjustedAt',
            label: 'Last Adjusted',
        },
    ];

    return (
        <>
            <section className={style.header}>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => setYear(year - 1)}
                    disabled={isLoading}
                >
                    Prev Year
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => setYear(year + 1)}
                    disabled={isLoading}
                >
                    Next Year
                </button>
                <Loader type="inline" loading={isLoading} removeChildren>
                    <span>{year}</span>
                </Loader>
            </section>
            <section className={style.yearTable}>
                <Table
                    name="admin-dashboard-table"
                    saveState={false}
                    rowKey="month"
                    perPage={1000}
                    showPerPageOptions={false}
                    columns={columns}
                    data={data}
                    hideShowingRowsSummary
                />
            </section>
        </>
    );
};
