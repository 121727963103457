import React, { useEffect, useState } from 'react';
import axios from 'cccisd-axios';
import { client } from 'cccisd-apollo';
import { Formik, Field, Form } from 'cccisd-formik';
import IconInfo from 'cccisd-icons/info2';
import InputTime from '../InputTime';
import Loader from 'cccisd-loader';
import notifications from './notifications';
import projectsQuery from './projects.graphql';
import selfProjectsQuery from './selfProjects.graphql';
import style from './style.css';
import Table from 'cccisd-table';
import Tooltip from 'cccisd-tooltip';
import utils from './utils';
import { useLocation } from 'react-router-dom';
import validate from './validate';

const Boilerplate = window.cccisd.boilerplate;
const TimeTracker = window.cccisd.timeTracker;

export default ({ timeQuery, employeeId, renderHeader, renderTools, renderButtons, self = false }) => {
    const urlSearchParams = new URLSearchParams(useLocation().search);
    const initialYear = urlSearchParams.get('year');
    const initialMonth = urlSearchParams.get('month');
    const initialWeek = urlSearchParams.get('week');

    const [projects, setProjects] = useState([]);
    const [year, setYear] = useState(initialYear || undefined);
    const [month, setMonth] = useState(initialMonth || undefined);
    const [week, setWeek] = useState(initialWeek || undefined);
    const [isValidWeek, setIsValidWeek] = useState(true);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isApproved, setApproved] = useState(false);
    const [isCalculated, setCalculated] = useState(false);
    const [weekFrom, setWeekFrom] = useState(null);
    const [weekTo, setWeekTo] = useState(null);
    const [dayColumns, setDayColumns] = useState(utils.dayColumnsDefault);
    const [initialValues, setInitialValues] = useState(utils.initialValuesDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [submittingMode, setSubmittingMode] = useState(undefined);

    useEffect(() => {
        projects.length === 0 && getProjectsAndTime();
    }, [projects]);

    useEffect(() => {
        projects.length !== 0 && getTime();
    }, [projects, employeeId]);

    const getProjectsAndTime = async () => {
        setIsLoading(true);

        setProjects(
            (await projectList()).map(project => ({
                projectId: project.group.groupId,
                label: project.group.label,
                description: project.childRoles.metricspawn.assignmentProgress.devTags.projectDescription,
                projectOrder: project.projectOrder,
            }))
        );

        getTime();
    };

    const projectList = async () => {
        if (self) {
            const selfResponse = await client.query({
                query: selfProjectsQuery,
                fetchPolicy: 'network-only',
                variables: { adminProjectId: TimeTracker.config['admin-project-id'] },
            });

            if (selfResponse.data.timeTracker.selfEmployee.projectList.length) {
                return selfResponse.data.timeTracker.selfEmployee.projectList;
            }
        }

        const response = await client.query({
            query: projectsQuery,
            fetchPolicy: 'network-only',
            variables: { adminProjectId: TimeTracker.config['admin-project-id'] },
        });

        return response.data.groups.projectList;
    };

    const getTime = async (weekChange = undefined) => {
        setIsLoading(true);
        setInitialValues(utils.initialValuesDefault);
        setWeekFrom(null);
        setWeekTo(null);

        const response = await client.query({
            query: timeQuery,
            fetchPolicy: 'network-only',
            variables: { employeeId, year, month, week, weekChange },
        });

        const { selfEmployee } = response.data.timeTracker;
        const responseTimeList = selfEmployee.timeList || selfEmployee.myEmployee.timeList;
        const responseWeek = selfEmployee.week || selfEmployee.myEmployee.week;
        const newDayColumns = utils.dayColumnsDefault.map(column => ({
            ...column,
            date: responseWeek.current[column.name],
        }));
        const newInitialValues = {
            timeData: responseTimeList.reduce((previousValue, currentValue) => {
                return { ...previousValue, [currentValue.projectId]: currentValue.timeData };
            }, {}),
            year: responseWeek.current.year,
            month: responseWeek.current.month,
            week: responseWeek.current.week,
            employeeId,
        };

        setYear(responseWeek.current.year);
        setMonth(responseWeek.current.month);
        setWeek(responseWeek.current.week);
        setIsValidWeek(!!newDayColumns.find(d => d.date));
        setSubmitted(!!responseWeek.submitted);
        setApproved(!!responseWeek.approved);
        setCalculated(!!responseWeek.current.calculated);
        setInitialValues(newInitialValues);
        setDayColumns(newDayColumns);
        setWeekFrom(utils.weekFrom(newDayColumns));
        setWeekTo(utils.weekTo(newDayColumns));
        setIsLoading(false);
    };

    const onSubmit = async (formikProps, route, extraValues) => {
        const { values, submitForm, setSubmitting, setErrors } = formikProps;

        const errors = validate(values);
        const errorsCount = Object.keys(errors).length;

        if (errorsCount > 0) {
            notifications.validationFailed(errorsCount);
            return setErrors(errors);
        }

        setIsLoading(true);
        setSubmittingMode(route);
        submitForm();

        try {
            await axios.post(Boilerplate.route(`api.monitoring.time-tracker.${route}`), { ...values, ...extraValues });
        } catch (e) {
            setSubmitting(false);
            setSubmittingMode(undefined);
            setIsLoading(false);

            if (e.toString().includes('405')) {
                notifications.accessFailed();
            }

            throw e;
        }

        setSubmitting(false);
        setSubmittingMode(undefined);

        await getTime();
    };

    const columns = [
        {
            name: 'label',
            label: 'Projects',
            render: (value, row) => {
                if (!row.description) {
                    return value;
                }

                return (
                    <>
                        {value}{' '}
                        <Tooltip title={row.description} placement="right">
                            <IconInfo />
                        </Tooltip>
                    </>
                );
            },
        },
        ...dayColumns.map(({ name, label, date }) => ({
            name,
            label: utils.columnLabel(label, date),
            render: (value, row) => {
                if (!date) {
                    return null;
                }

                return (
                    <Field
                        name={`timeData.${row.projectId}.${date}`}
                        disabled={isLoading || isCalculated || (isSubmitted && employeeId === undefined)}
                        component={InputTime}
                    />
                );
            },
        })),
    ];

    const data = () => {
        if (self) {
            return projects;
        }

        const rows = projects.map(project => ({
            ...project,
            projectOrder: project.projectId in initialValues.timeData ? '-1' : project.projectOrder,
        }));
        rows.sort((a, b) => a.projectOrder.localeCompare(b.projectOrder));

        return rows;
    };

    return (
        <Formik
            key={utils.initialValuesHash(initialValues)}
            initialValues={initialValues}
            render={formikProps => {
                const handleSubmit = (route, extraValues = {}) => onSubmit(formikProps, route, extraValues);

                return (
                    <Form>
                        <section className={style.header}>
                            <div className={style.buttons}>
                                <div>{renderHeader(isLoading)}</div>
                                <div>{renderTools(isLoading, year, month, getProjectsAndTime)}</div>
                            </div>
                            <div className={style.buttons}>
                                <div className={style.weekSwitch}>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => getTime(-1)}
                                        disabled={isLoading}
                                    >
                                        Prev Week
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => getTime(1)}
                                        disabled={isLoading}
                                    >
                                        Next Week
                                    </button>
                                    <Loader type="inline" loading={isLoading && !submittingMode} removeChildren>
                                        {!!weekFrom && (
                                            <span>
                                                Week <strong>{week}</strong>, {weekFrom} - {weekTo}
                                            </span>
                                        )}
                                    </Loader>
                                </div>
                                <div>
                                    {!!weekFrom && isApproved && (
                                        <span className={style.status}>
                                            <span className="bg-success text-white">Approved</span>
                                        </span>
                                    )}
                                    {!!weekFrom && isSubmitted && !isApproved && (
                                        <span className={style.status}>
                                            <span className="bg-warning text-white">Submitted</span>
                                        </span>
                                    )}
                                    {!!weekFrom && <span>Hours this week: {utils.hoursThisWeek(formikProps)}</span>}
                                    {renderButtons(
                                        handleSubmit,
                                        isSubmitted,
                                        isApproved,
                                        isCalculated,
                                        isValidWeek,
                                        submittingMode,
                                        isLoading,
                                        weekFrom
                                    )}
                                </div>
                            </div>
                        </section>
                        <section className={style.timeTable}>
                            <Table
                                name="time-table"
                                saveState={false}
                                rowKey="projectId"
                                perPage={1000}
                                showPerPageOptions={false}
                                columns={columns}
                                data={data()}
                                wrapperClassName={style.tableWrapper}
                                hideShowingRowsSummary
                            />
                        </section>
                    </Form>
                );
            }}
        />
    );
};
